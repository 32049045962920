import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/chatBOt.png";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiFillInstagram } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
            AI-Powered <span className="purple"> Innovations  </span> for Every Journey
            </h1>
            <p className="home-about-body">
            Choose Vullnet, for the most advanced AI-powered chatbot platform, designed to address the specifics of business in every industry. Whether you manage a business, events, campaigns or conference organization, Vullnet easily adapts to the needs of the customer, in any language and in any situation.
              <br />
              <br />I am fluent in classics like
              <i>
                <b className="purple"> C# and Javascript. </b>
              </i>
              <br />
              <br />
              My field of Interest's are building new &nbsp;
              <i>
                <b className="purple">Software and AI Development </b> and
                <b className="purple"></b>
              </i>
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with{" "}
              <i>
                {" "}
                <b className="purple">AI</b>
              </i>{" "}
              and
              <i>
                <b className="purple"> Modern Language</b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> C# & Javascript</b>
              </i>
            </p>
          </Col>
          <Col md={3} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>CONTACT ME</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Vullnet02"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/profile.php?id=100092493776441"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/vjakupi_/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
