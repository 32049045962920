import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const MetaTag = ({ title }) => {
  const [href, setHref] = useState("");
  useEffect(() => {
    setHref(window.location.href);
  }, []);
  console.log(href);
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={href} />
    </Helmet>
  );
};

export default MetaTag;