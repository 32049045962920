import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import viber from "../../Assets/Projects/viber.png";
import reactjs from "../../Assets/Projects/reactjs.png";
import screenshotPhoto from "../../Assets/Projects/screenshotPhoto.png";
import MetaTag from "../Helmet";

function Projects() {
  return (
    <Container fluid className="project-section">
      <MetaTag title={"Vullnet | Project"} />

      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a Three projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={reactjs}
              isBlog={false}
              title="React JS - Project"
              description="This is a website created by me at a time when I was in academia, we have a final project, here I have shown my idea of what React JS serves, as a JavaScript framework that offers many possibilities as a programming language."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={screenshotPhoto}
              isBlog={false}
              title="A website for screenshot program"
              description="In this project I worked with React JS and created a website that serves several options on how to use screenshots and how it works with its features that have the Screenshot option."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={viber}
              isBlog={false}
              title="The Personal Old Website"
              description="My personal blog page build with React.js and Tailwind Css which takes the content from makdown files and renders it using React.js. Supports dark mode and easy to write blogs using markdown."
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
